import FroalaEditor from 'froala-editor';
import applyDynamicTagging from '../behaviours/dynamic_tagging';
import { get } from '../utils/axios_request';

const MAX_RAND = 1000;
const TAGS = ['removing index 0'];
const BASIC_TAGS = [];

const generateTimeStamp = () => Date.now() + Math.floor(Math.random() * MAX_RAND) + 1;

const getAvailableTags = selector => $(selector).data('tags');

const getPrefilledTags = selector => $(selector).data('prefilledTags');

const buildListItem = (name, value) => (
  `
    <li class="tags-list">
      <div class="tags"> 
        <a class="fr-command" data-cmd="placeholdersDropdown" data-param1="${value}" data-param2="${name}" title="${name}">
          ${name.slice(1, -1).replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}
        </a>
      </div>
    </li>
  `
);

const addTagsDropdown = (translations, callback) => {
  localStorage.removeItem('singleContract');
  localStorage.removeItem('tagsToAdd');
  FroalaEditor.DefineIcon('dropdownIcon', { NAME: 'tags' });
  const tagPreview = {
    '{applicant_first_name}': `<span class='fr-deletable fr-tribute tags-list'>Applicant First Name</span>`,
    '{applicant_full_name}': `<span class='fr-deletable fr-tribute tags-list'>Applicant Full Name</span>`,
    '{hiring_contract}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Contract</span>`,
    '{hiring_full_mame}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Full Name</span>`,
    '{hiring_name}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Name</span>`,
    '{hiring_email}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Email</span>`,
    '{hiring_phone_number}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Phone Number</span>`,
    '{hiring_mobile_phone}': `<span class='fr-deletable fr-tribute tags-list'>Hiring Mobile Phone</span>`,
    '{position_department}': `<span class='fr-deletable fr-tribute tags-list'>Position Department</span>`
  }
  const optionsTags = TAGS[1] ? tagPreview : BASIC_TAGS[0]
  FroalaEditor.RegisterCommand('placeholdersDropdown', {
    title: translations.tooltip,
    type: 'dropdown',
    icon: 'dropdownIcon',
    undo: true,
    refreshAfterCallback: true,
    callback,
    options: optionsTags,
    refreshOnShow: ($btn, $dropdown) => {
      const canUseNormallTags = localStorage.getItem('singleContract') == 'true' || TAGS[1] == undefined;
      const tagsToAdd = JSON.parse(localStorage.getItem('tagsToAdd'));
      if (tagsToAdd) {
        tagsToAdd.forEach(function(tagToAdd) {
          const tag = tagToAdd.replace(/ /g, "_")
          var newItem = {name: `{${tag}}`, value: ''};
          if (TAGS[1]) {
            TAGS[1].forEach(function(element) {
              element.push(newItem);
            });
          }
          BASIC_TAGS[0][`{${tag}}`] = `{${tag}}`;
        });
        localStorage.removeItem('tagsToAdd');
      }
      const tags = canUseNormallTags ? BASIC_TAGS[0] : TAGS[1][$btn.attr('id').slice(-1)-1];
      const list = $dropdown.find('ul.fr-dropdown-list');
      let listItems = '';
      const tagsUrl = document.querySelector('.froala-editor').dataset.customContractTagsPath;
      get(tagsUrl).then((response) => {
        if (canUseNormallTags) {
          Object.keys({ ...tags, ...response.data.tags }).forEach((t) => {
            listItems += buildListItem(t, t);
          });
        } else {
          Object.keys({ ...tags, ...response.data.tags }).forEach((t) => {
            if (tags && tags[t]) {
              listItems += buildListItem(tags[t].name, tags[t].value);
            } 
          });
        }
        list.empty();
        list.append(listItems);
      });
    }
  });
};

const addPlaceholdersDropdown = (selector, translations) => {
  const normalTags = getAvailableTags(selector);
  const prefilledTags = getPrefilledTags(selector);
  TAGS.push(prefilledTags);
  const availableTags = typeof prefilledTags === 'undefined' 
    ? normalTags
    : prefilledTags[0];

  if (availableTags == null) {
    return false;
  }
  if (normalTags) {
    BASIC_TAGS.push(normalTags.split(', ').reduce((result, item) => {
      result[item] = item;
      return result;
    }, {}));
  }
  if (!$(selector).hasClass('froala-dynamic-tagging')) {
    // use regular tags
    addTagsDropdown(translations, function(_cmd, val, _name) {
      return this.html.insert(val);
    });
  } else {
    // use dynamic tags via tribute js
    addTagsDropdown(translations, function (_cmd, val, name) {
      // eslint-disable-next-line quotes
      const tagName = name.slice(1, -1).replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
      const canUseNormallTags = localStorage.getItem('singleContract') == 'true' || TAGS[1] == undefined;
      const NotAutofilledTag = val === ''
      const tag = NotAutofilledTag ? tagName : val;
      const tagToInsert = canUseNormallTags
        ? `<span class='fr-deletable fr-tribute' name='${tagName}'>${tagName}</span>\u200B &nbsp`
        : `<span class='fr-deletable fr-tribute${NotAutofilledTag ? ' tag-error-color' : ''}' name='${tagName}'>${tag}</span>\u200B &nbsp`;
      return this.html.insert(tagToInsert);
    });
  }
  return true;
};

const prepareTranslations = ($froalaEditor) => {
  var ref, ref1, tableStyle, tooltip;
  tooltip = (ref = $froalaEditor.data('translations')) != null ? ref.placeholder_tooltip : void 0;
  tableStyle = (ref1 = $froalaEditor.data('translations')) != null ? ref1.table_style : void 0;
  if (tooltip == null) {
    tooltip = 'Placeholders';
  }
  if (tableStyle == null) {
    tableStyle = 'Transparent borders';
  }
  return { tooltip, tableStyle };
};

const linkEditWithoutStyling = () => FroalaEditor.DEFAULTS.linkEditButtons.filter(b => b !== 'linkStyle');

const tableStyles = (translations) => {
  const styles = {
    'fr-transparent-table-border': translations.tableStyle
  };
  const defaults = FroalaEditor.DEFAULTS.tableStyles;
  Object.keys(defaults).forEach((key) => { styles[key] = defaults[key] });
  return styles;
};

const fontFamilies = (fonts) => {
  const families = {};
  if (fonts) fonts.forEach((font) => { families[font + ', sans-serif'] = font });
  const defaults = FroalaEditor.DEFAULTS.fontFamily;
  Object.keys(defaults).forEach((key) => { families[key] = defaults[key] });
  return families;
};

const defineFullTagsDropdown = () => {
  FroalaEditor.DefineIcon('template_tags_dropdown', { NAME: 'tag' });
  FroalaEditor.RegisterCommand('template_tags_dropdown', {
    title: 'Advanced options',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: {
      '{hiring_full_name}': 'Hiring Full Name',
      '{hiring_email}': 'Hiring Email',
      '{hiring_mobile_phone}': 'Hiring Mobile Phone',
      '{hiring_office_phone}': 'Hiring Office Phone',
      '{job_posting_header}': 'Job Posting Header',
      '{job_posting_footer}': 'Job Posting Footer',
      '{organization_name}': 'Organization Name',
      '{position_title}': 'Position Title',
      '{position_department}': 'Position Department',
      '{position_closing_date}': 'Position Closing Date',
      '{responsible_full_name}': 'Responsible Full Name',
      '{responsible_email}': 'Responsible Email',
      '{responsible_mobile_phone}': 'Responsible Mobile Phone',
      '{responsible_office_phone}': 'Responsible Office Phone'
    },
    callback (cmd, val) {
      this.html.insert(val);
    }
  });
};

const attachTributeTagger = (editor, selector) => {
  const availableTags = getAvailableTags(selector);
  const tribute = TAGS[1] 
    ? applyDynamicTagging(availableTags, TAGS[1][editor.id - 1]) 
    : applyDynamicTagging(availableTags, []);

  tribute.attach(editor.el);
  window.tribute = tribute;

  editor.events.on('keydown', (e) => {
    if (e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
      return false;
    }
    return true;
  }, true);
};

const pasteReplacer = (clipboardHtml) => {
  const clipWithTributes = clipboardHtml.replace(
    /<span>{([a-z|0-9|_]*)}<\/span>/g,
    '<span class="fr-deletable fr-tribute">{$1}</span>'
  );
  const matches = Array.from(
    clipWithTributes.matchAll(/<input[^>]*name="([0-9]*)"[^>]*>/g),
    m => m[1]
  );
  const uniqueInputStamps = [...new Set(matches)];
  let clipWithStampReplace = clipWithTributes;
  uniqueInputStamps.forEach((stamp) => {
    const newStamp = generateTimeStamp();
    clipWithStampReplace = clipWithStampReplace.replace(
      new RegExp(stamp, 'g'), newStamp
    );
  });
  console.log(`Unique stamps: ${uniqueInputStamps.length}`);
  const inputDivsLength = (clipWithStampReplace.match(/<div\sclass="fr-form">/g) || []).length;
  console.log(`Input divs: ${inputDivsLength}`);
  return clipWithStampReplace;
};

const moreButtons = (cmd, editor) => {
  editor.toolbar.setMoreToolbarsHeight();
    function moreExec(cmd) {
      let $btn = editor.$tb.find("[data-cmd=".concat(cmd, "]"));
      toggleMoreButton($btn);
      editor.toolbar.setMoreToolbarsHeight();
    }

    function toggleMoreButton($btn) {
      let $buttonGroup = editor.$tb.find(".fr-more-toolbar[data-name=\"".concat($btn.attr('data-group-name'), "\"]"));
      
      editor.$tb.find('.fr-open').not($btn).removeClass('fr-open');
      $btn.toggleClass('fr-open');
      editor.$tb.find('.fr-more-toolbar').removeClass('fr-overflow-visible');

      if (editor.$tb.find('.fr-expanded').not($buttonGroup).length) {
        editor.$tb.find('.fr-expanded').toggleClass('fr-expanded');
        $buttonGroup.toggleClass('fr-expanded');
      } else {
        $buttonGroup.toggleClass('fr-expanded');
        editor.$box.toggleClass('fr-toolbar-open');
        editor.$tb.toggleClass('fr-toolbar-open');
      }
    }
    moreExec(cmd);
};

export {
  generateTimeStamp, addPlaceholdersDropdown, prepareTranslations, tableStyles, fontFamilies,
  defineFullTagsDropdown, linkEditWithoutStyling, attachTributeTagger, pasteReplacer, moreButtons
};
