import FroalaEditor from 'froala-editor';
import { rawData as i18n } from '../utils/i18n';
import { generateTimeStamp, moreButtons } from './helpers';

// Define popup template.
FroalaEditor.POPUP_TEMPLATES['formElements.popup'] = '[_YN_INPUT_][_DIVIDER_][_TEXT_INPUT_][_DIVIDER2_][_CHECKBOX_INPUT_]';

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
  formElementsYnConfirm: ['addYn'],
  formElementsTextConfirm: ['addText'],
  formElementsCheckboxConfirm: ['addCheckbox']
});

// The custom popup is defined inside a plugin (new or existing).
FroalaEditor.PLUGINS.formElements = function (editor) {
  // Create custom popup.
  function initPopup () {
    // Load popup template.
    const template = {
      yn_input: `
        <div class="fr-form-elements p-2">
          <label class="mt-3" for="fr-form-content-yn">${i18n.froala_form_elements.yes_no}</label>
          <div class="d-flex">
            <input class="fr-form-content" id="fr-form-content-yn" type="text" />
            ${editor.button.buildList(editor.opts.formElementsYnConfirm)}
          </div>
        </div>
      `,
      divider: '<hr>',
      text_input: `
        <div class="fr-form-elements p-2">
          <label class="mt-3" for="fr-form-content-text">${i18n.froala_form_elements.open}</label>
          <div class="d-flex">
            <input class="fr-form-content" id="fr-form-content-text" type="text" />
            ${editor.button.buildList(editor.opts.formElementsTextConfirm)}
          </div>
        </div>
      `,
      divider2: '<hr>',
      checkbox_input: `
        <div class="fr-form-elements p-2">
          <label class="mt-3 for="fr-form-content-checkbox>
            ${i18n.froala_form_elements.checkbox}
          </label>
          <div class="d-flex">
            <input class="fr-form-content" id="fr-form-content-checkbox" type="text" />
            ${editor.button.buildList(editor.opts.formElementsCheckboxConfirm)}
          </div>
        </div>
      `
    };

    // Create popup.
    const $popup = editor.popups.create('formElements.popup', template);
    // Block form submit on inputs
    document.querySelectorAll('.fr-form-content').forEach((input) => {
      input.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    });
    return $popup;
  }

  // Show the popup
  function showPopup () {
    // Get the popup object defined above.
    let $popup = editor.popups.get('formElements.popup');

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    if (!$popup) $popup = initPopup();

    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer('formElements.popup', editor.$tb);

    // This will trigger the refresh event assigned to the popup.
    // editor.popups.refresh('formElements.popup');

    // This custom popup is opened by pressing a button from the editor's toolbar.
    // Get the button's object in order to place the popup relative to it.
    const $btn = editor.$tb.find('.fr-command[data-cmd="formElementsOpen"]');

    // Set the popup's position.
    const left = $btn.offset().left + $btn.outerWidth() / 2;
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    editor.popups.show('formElements.popup', left, top, $btn.outerHeight());
  }

  // Hide the custom popup.
  function hidePopup () {
    editor.popups.hide('formElements.popup');
  }

  function yesNoQuestionHtml() {
    const inputContent = document.getElementById('fr-form-content-yn').value;
    document.getElementById('fr-form-content-yn').value = '';
    const stamp = generateTimeStamp();
    return `
      <div class="fr-form">
        <span class="input-question" data-question>${inputContent}</span>
        <br />
        <input disabled data-type="yes_no" type="radio" name="${stamp}" id="${stamp}-yes" value="true" />
        <label for="${stamp}-yes">${i18n.common.answer_yes}</label>
        <input disabled data-type="yes_no" type="radio" name="${stamp}" id="${stamp}-no" value="false" />
        <label for="${stamp}-no">${i18n.common.answer_no}</label>
      </div>
    `;
  }

  function textQuestionHtml() {
    const inputContent = document.getElementById('fr-form-content-text').value;
    document.getElementById('fr-form-content-text').value = '';
    const stamp = generateTimeStamp();
    return `
      <div class="fr-form">
        <span class="input-question" data-question>${inputContent}</span>
        <br />
        <input disabled class="fr-input-text" data-type="text" type="text" name="${stamp}" id="${stamp}-text" />
      </div>
    `;
  }

  function checkboxQuestionHtml() {
    const inputContent = document.getElementById('fr-form-content-checkbox').value;
    document.getElementById('fr-form-content-checkbox').value = '';
    const stamp = generateTimeStamp();
    return `
      <div class="fr-form">
        <input disabled data-type="checkbox" type="checkbox" name="${stamp}" id="${stamp}-checkbox" />
        <span class="input-question" data-question>${inputContent}</span>
      </div>
    `;
  }

  // Methods visible outside the plugin.
  return {
    showPopup,
    hidePopup,
    yesNoQuestionHtml,
    textQuestionHtml,
    checkboxQuestionHtml
  };
};

// Define an icon and command for the button that opens the custom popup.
FroalaEditor.DefineIcon('formElementsIcon', { NAME: 'th' });
FroalaEditor.RegisterCommand('formElementsOpen', {
  title: 'Form elements',
  icon: 'formElementsIcon',
  undo: false,
  focus: false,
  plugin: 'formElements',
  callback() {
    this.formElements.showPopup();
  }
});

// Define Form element add commands
FroalaEditor.DefineIcon('addFormElement', { NAME: 'plus' });
FroalaEditor.RegisterCommand('addYn', {
  title: 'Add form element',
  icon: 'addFormElement',
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  callback() {
    const html = this.formElements.yesNoQuestionHtml();
    this.html.insert(html);
    this.undo.saveStep();
    this.formElements.hidePopup();
  }
});

FroalaEditor.RegisterCommand('addText', {
  title: 'Add form element',
  icon: 'addFormElement',
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  callback() {
    const html = this.formElements.textQuestionHtml();
    this.html.insert(html);
    this.undo.saveStep();
    this.formElements.hidePopup();
  }
});

FroalaEditor.RegisterCommand('addCheckbox', {
  title: 'Add form element',
  icon: 'addFormElement',
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  callback() {
    const html = this.formElements.checkboxQuestionHtml();
    this.html.insert(html);
    this.undo.saveStep();
    this.formElements.hidePopup();
  }
});

FroalaEditor.DefineIcon('moreTextButtons', {
  NAME: 'ellipsis-h',
  SVG_KEY: 'moreTextButtons'
});

FroalaEditor.RegisterCommand('moreTextButtons', {
  icon: 'moreTextButtons',
  title: 'More text buttons',
  undo: false,
  more_btn:false,
  callback: function(cmd) {
    const editor = this
    moreButtons(cmd, editor)
  }
});

FroalaEditor.DefineIcon('moreAdditionalButtons', {
  NAME: 'ellipsis-h',
  SVG_KEY: 'moreAdditionalButtons'
});

FroalaEditor.RegisterCommand('moreAdditionalButtons', {
  icon: 'moreAdditionalButtons',
  title: 'More...',
  undo: false,
  more_btn:false,
  callback: function(cmd) {
    const editor = this
    moreButtons(cmd, editor)
  }
});

FroalaEditor.DefineIcon('iconDivider', {
  NAME: '',
  SVG_KEY: 'divider'
});

FroalaEditor.RegisterCommand('iconDivider1', {
  icon: 'iconDivider',
  title: '',
  undo: false,
  redo: false,
  disabled: true
});

FroalaEditor.RegisterCommand('iconDivider2', {
  icon: 'iconDivider',
  title: '',
  undo: false,
  redo: false,
  disabled: true
});

FroalaEditor.RegisterCommand('iconDivider3', {
  icon: 'iconDivider',
  title: '',
  undo: false,
  redo: false,
  disabled: true
});

FroalaEditor.RegisterCommand('iconDivider4', {
  icon: 'iconDivider',
  title: '',
  undo: false,
  redo: false,
  disabled: true
});

FroalaEditor.RegisterCommand('iconDivider5', {
  icon: 'iconDivider',
  title: '',
  undo: false,
  redo: false,
  disabled: true
});
