
const availableToolbars = {
  full: {
    moreText: {
      buttons: [
        'bold', 'italic', 'underline', 'fontSize', 'fontFamily', 'paragraphFormat'
      ],
      buttonsVisible: 'all'
    },
    moreTextButtons: {
      buttons: [
        'formatUL', 'textColor'
      ],
      buttonsVisible: 3,
    },
    moreParagraph: {
      buttons: [
        , 'alignLeft', 'alignCenter','alignRight', 'alignJustify'
      ],
      buttonsVisible: 'all'
    },
    moreMisc: {
      buttons: [
        'undo', 'redo'
      ],
      buttonsVisible: 'all'
    },
    moreAdditionalButtons: {
      buttons: [
        'strikeThrough','subscript', 'superscript','inlineStyle', 'iconDivider1', 
        'emoticons', 'specialCharacters', 'selectAll', 'clearFormatting', 
        'iconDivider2',
        'paragraphStyle', 'lineHeight', 'iconDeiider3',
        'insertLink','insertImage', 'insertVideo', 'insertFile', 'insertTable', 'iconDivider4',
        'formatOL', 'outdent', 'indent', 'quote', 'iconDivider5',
        'fullscreen', 'print', 'help', 'html', 'insertHR'
      ],
      buttonsVisible: 0
    }
  },
  mini: {
    moreText: {
      buttons: [
        'bold', 'italic', 'underline', 'fontSize', 'fontFamily', 'paragraphFormat'
      ],
      buttonsVisible: 'all'
    },
    moreTextButtons: {
      buttons: [
        'formatUL', 'textColor'
      ],
      buttonsVisible: 3
    },
    moreParagraph: {
      buttons: [
        'alignLeft', 'alignCenter', 'alignRight', 'alignJustify'
      ],
      buttonsVisible: 4
    },
    moreMisc: {
      buttons: [
        'undo', 'redo'
      ]
    },
    moreAdditionalButtons: {
      buttons: [
        'lineHeight', 'formatOL' , 'outdent', 'indent', 'iconDivider1',
        'insertLink', 'insertImage', 'iconDivider2',
        'help', 'html'
      ],
      buttonsVisible: 0
    }
  },
  contract: {
    moreText: {
      buttons: [
        'bold', 'italic', 'underline', 'fontSize', 'fontFamily', 'paragraphFormat'
      ],
      buttonsVisible: 'all'
    },
    moreTextButtons: {
      buttons: [
        'formatUL', 'textColor'
      ]
    },
    moreParagraph: {
      buttons: [
        'alignLeft', 'alignCenter', 'alignRight', 'alignJustify'
      ],
      buttonsVisible: 4
    },
    moreMisc: {
      buttons: [
        'undo', 'redo', 'formElementsOpen'
      ],
      buttonsVisible: 6
    },
    moreAdditionalButtons: {
      buttons: [
        'lineHeight', 'formatOL', 'formatUL', 'outdent', 'indent', 'iconDivider1',
        'insertLink', 'insertImage', 'insertTable', 'iconDivider2',
        'help', 'html'

      ],
      buttonsVisible: 0
    }
  },
  jobnet: {
    moreText: {
      buttons: ['bold', 'italic', 'underline']
    },
    moreParagraph: {
      buttons: ['formatOL', 'formatUL']
    },
    moreRich: {
      buttons: ['insertLink']
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'html']
    }
  },
  customLayout: {
    moreText: {
      buttons: ['bold', 'italic', 'underline', 'fontSize', 'fontFamily', 'paragraphFormat'],
      buttonsVisible: 'all'
    },
    moreTextButtons: {
      buttons: ['formatUL', 'textColor']
    },
    moreParagraph: {
      buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
      buttonsVisible: 'all'
    },
    moreRich: {
      buttons: ['insertLink']
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'html']
    }
  },
  minimal: {
    moreText: {
      buttons: ['bold', 'italic', 'underline']
    },
    moreParagraph: {
      buttons: ['formatOL', 'formatUL']
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'html']
    }
  }
};

export default availableToolbars;
