import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'da'
});

const rawData = window.LocaleData.translations[window.Locale];
i18n.setLocaleMessage(window.Locale, rawData);

export { i18n, rawData };
