import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

const applyDynamicTagging = (tags, prefilledTags) => {
  const isSingleContract = localStorage.getItem('singleContract') === 'true';
  const dynamicValues = (isSingleContract ? tags : prefilledTags)
    .map(item => {
      const key = item.name ? item.name.slice(1, -1) : item.slice(1, -1);
      const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
      return { key: formattedKey, value: item.value || item };
    });

  const tribute = new Tribute({
    values: dynamicValues,
    trigger: '{',
    selectTemplate(item) {
      const key = item.original.key
      const value = item.original.value
      let tagValue = value || key;
      if (typeof value === 'string' && value != '') {
        tagValue = value;
      } else {
        tagValue = key
      }
      const NotAutofilledTag = tagValue == key
      const tagToInsert = localStorage.getItem('singleContract') === 'true'
        ? `<span class='fr-deletable fr-tribute'>${key}</span>\u200B &nbsp`
        : `<span class='fr-deletable fr-tribute${NotAutofilledTag ? ' tag-error-color' : ''}' name='${key}'>${tagValue}</span>\u200B &nbsp`;

      return tagToInsert;
    }
  });

  return tribute;
};

export default applyDynamicTagging;
