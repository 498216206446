import 'core-js/stable';
import 'regenerator-runtime/runtime';

import FroalaEditor from 'froala-editor';
import pluginsList from '../initializers/init_froala_plugins';
import availableToolbars from '../froala/toolbars';
import {
  prepareTranslations, addPlaceholdersDropdown, tableStyles, fontFamilies,
  defineFullTagsDropdown, linkEditWithoutStyling, attachTributeTagger, pasteReplacer
} from '../froala/helpers';
import '../froala/plugins';

window.FroalaInitialize = (selector, froalaFonts) => {
  const $froala = $(selector);
  const translations = prepareTranslations($froala);
  const shouldRenderTags = addPlaceholdersDropdown(selector, translations);
  const options = {
    htmlAllowedAttrs: [...FroalaEditor.DEFAULTS.htmlAllowedAttrs, 'filled-by-candidate'],
    language: $froala.data('language'),
    iconsTemplate: 'font_awesome_5',
    imageUploadURL: $froala.data('upload-path') + '.json',
    imageUploadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      type: 'image'
    },
    imageManagerLoadURL: $froala.data('preview-path') + '.json',
    imageManagerLoadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content')
    },
    imageManagerDeleteURL: $froala.data('destroy-path') + '.json',
    imageManagerDeleteParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      id: $froala.data('id')
    },
    imageManagerDeleteMethod: 'DELETE',
    videoUploadURL: $froala.data('upload-path') + '.json',
    videoUploadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      type: 'video'
    },
    fileUploadURL: $froala.data('upload-path') + '.json',
    fileUploadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      type: 'file'
    },
    tableStyles: tableStyles(translations),
    fontFamily: fontFamilies(froalaFonts),
    fontSizeSelection: true,
    fontFamilySelection: true,
    paragraphFormatSelection: true,
    zIndex: 1
  };
  if ($froala.data('disable-edit')) {
    options.events = {
      initialized() {
        this.edit.off();
      }
    };
  }
  if ($froala.hasClass('froala-minified')) {
    options.toolbarButtons = availableToolbars.mini;
  } else if ($froala.hasClass('froala-minimal')) {
    options.toolbarButtons = availableToolbars.minimal;
    options.pluginsEnabled = [...pluginsList.filter(p => p !== 'quickInsert')];
  } else if ($froala.hasClass('froala-jobnet')) {
    options.toolbarButtons = availableToolbars.jobnet;
    options.linkEditButtons = linkEditWithoutStyling();
    options.pluginsEnabled = ['lists', 'codeView', 'lineBreaker', 'link'];
  } else {
    options.toolbarButtons = availableToolbars.full;
  }
  if ($froala.hasClass('froala-contract-document')) {
    options.documentReady = true;
    options.pasteDeniedAttrs = [];
    options.toolbarButtons = availableToolbars.contract;
    options.pluginsEnabled = [...pluginsList, 'formElements'];
  }
  if ($froala.hasClass('froala-dynamic-tagging')) {
    options.events = {
      initialized() {
        attachTributeTagger(this, selector);
      },
      'paste.afterCleanup': function(clipboardHtml) {
        return pasteReplacer(clipboardHtml);
      }
    };
  }
  if ($froala.hasClass('froala-career-pages')) {
    options.toolbarButtons = availableToolbars.customLayout;
  }
  if ($froala.hasClass('froala-modal')) {
    const modalId = $froala.data('modal-id');
    options.scrollableContainer = `#${modalId}`;
  }
  const btns = options.toolbarButtons.moreMisc.buttons;
  if ($froala.hasClass('froala-media-module')) {
    defineFullTagsDropdown();
    if (!btns.includes('template_tags_dropdown')) {
      btns.unshift('template_tags_dropdown');
    }
  }
  if ($froala.hasClass('froala-inline-style')) {
    options.useClasses = false;
  }
  if (shouldRenderTags) {
    if (!btns.includes('placeholdersDropdown')) {
      btns.unshift('placeholdersDropdown');
    }
  }
  return new FroalaEditor(
    selector,
    {
      key: process.env.FROALA_PRO_KEY,
      attribution: false,
      ...options
    }
  );
};
